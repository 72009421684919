import React, { useContext } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { useFormik } from "formik";
import history from "../../utils/history";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import FooterSection from "../../views/SectionsPage/Sections/FooterSection.js";
import HeaderSection from "../../views/SectionsPage/Sections/HeaderSection.js";
import LinkSection from "../../views/SectionsPage/Sections/LinksSection.js";
import TextField from "components/CustomInput/CustomInput.js";
import Iframe from "react-iframe";
import fnmoment from "moment";
import Context from "../../utils/context";
import { useDispatch, useSelector } from "react-redux";
import * as ACTIONS from "../../store/actions/actions";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";

import {
  apiActions,
  getSiteDetailsActions,
  htmlActions,
} from "../../store/redux/redux-actions/redux_actions";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from "@material-ui/core/Fade";

import Link from "@material-ui/core/Link";
import * as URLS from "../../utils/XRSAPIs";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import { Collapse } from "@material-ui/core";

import html2canvas from "html2canvas";
import Success from "components/Typography/Success";
import { useWindowSize } from "components/camera/Camera";
import { textVariables } from "assets/jss/xrs";

const useStyles = makeStyles(landingPageStyle);
let eventListenerCopy = null;
export default function QuickPayPage() {
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(1);
  const [disableButton, setdisableButton] = React.useState(false);

  const [ifamevisible, setIfamevisible] = React.useState(false);
  const [sitename, setSitename] = React.useState("");
  const [companyname, setCompanyname] = React.useState("");
  const [aboutus, setAboutus] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [officehours, setOfficehours] = React.useState("");
  const [phonenumber, setPhonenumber] = React.useState("");

  const [email, setEmail] = React.useState("");
  const loading = useSelector((state) => state.ApiCallsReducer.loading);
  const currency = useSelector((state) => state.GetSiteDetailsReducer.currency);
  const paymentProvider = useSelector(
    (state) => state.GetSiteDetailsReducer.paymentProvider
  );
  const convenienceFee = useSelector(
    (state) => state.GetSiteDetailsReducer.convenienceFee
  );
  const [registrationType, setRegistrationType] = React.useState("both");
  const [
    selectedregistrationType,
    setselectedRegistrationType,
  ] = React.useState(null);

  const [useSSN, setSSN] = React.useState(false);

  const context = useContext(Context);

  const [minimumPayAmount, setMinimumPayAmount] = React.useState("");
  const [minimumDob, setMinimumDob] = React.useState("");
  const [maximumDob, setMaximumDob] = React.useState("");

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertText, setAlertText] = React.useState(false);
  const [quickPayText, setQuickPayText] = React.useState("");
  const [paymentgatewayurl, setPaymentgatewayurl] = React.useState("");

  const [showQuickPay, setShowQuickPay] = React.useState(true);
  const [termsClicked, setTermsCliked] = React.useState(false);
  const [termsText, setTermsText] = React.useState("");
  const [termsApiText, setTermsApiText] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState("Test");
  const [screenTake, setScreenTakte] = React.useState("date");
  const [screenstate, setScreenstate] = React.useState({ screenshot: "" });
  const [customerCaption, setcustomerCaption] = React.useState("");
  const [quickPayAPIData, setquickPayAPIData] = React.useState(undefined);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  React.useEffect(() => {
    if (quickPayAPIData) {
      window.addEventListener("message", tabaPayMessage, false);
    }
    return () => {
      window.removeEventListener("message", tabaPayMessage);
    };
  }, [quickPayAPIData]);

  const HideIframe = () => {
    setIfamevisible(false);
  };
  const ShowIframe = () => {
    setIfamevisible(true);
  };
  const handleTermsMobile = () => {
    setShowQuickPay(false);
  };
  const HandleTermsClicked = (e) => {
    if (e.target.checked) {
      setTermsText("I agree with the ");
    }
    setTermsCliked(e.target.checked);
    setShowQuickPay(true);
  };
  const changeRegisterType = (e, registrationTypeprop) => {
    setselectedRegistrationType(registrationTypeprop);
  };
  React.useEffect(() => {
    window.RealexHpp = null;

    const script = document.createElement("script");
    script.src = "rxp-js.js";
    script.async = true;
    document.body.appendChild(script);
    dispatch(ACTIONS.set_Customer("NoCustomer"));

    dispatch(ACTIONS.set_Accounts("NoAccount"));
    dispatch(ACTIONS.set_selectedAccount("NoAccount"));

    fetch(URLS.GetSiteDetatilsAPIUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Welcome Details", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        dispatch(getSiteDetailsActions.logo(response.logoimageurl));
        dispatch(getSiteDetailsActions.footerImage(response.footerimageurl));
        dispatch(getSiteDetailsActions.currency(response.sitecurrency));
        dispatch(
          getSiteDetailsActions.uploadImagesToBudget(
            response.uploadimagestobudget
          )
        );

        let type = response.registrationtype
          ? response.registrationtype.toLowerCase()
          : null;
        dispatch(
          getSiteDetailsActions.uploadImagesToBudget(
            response.uploadimagestobudget
          )
        );
        if (response.usesconveniencefee) {
          setQuickPayText(response.quickpaytext);
          dispatch(
            getSiteDetailsActions.convenienceFee(response.conveniencefee)
          );
        }
        setselectedRegistrationType(
          type == "both" ? "personal" : type == "bothdob" ? null : type
        );
        setRegistrationType(type);
        setPaymentgatewayurl(response.paymentgatewayurl);
        setSSN(response.quickpayusessn);
        setSitename(response.sitename);
        setCompanyname(response.companyname);
        setAboutus(response.aboutus);
        setOfficehours(response.officehours);
        setPhonenumber(response.phonenumber);
        setAddress(response.address);
        setEmail(response.email);
        setMinimumPayAmount(response.minquickpay);
        setTermsApiText(response.termsquickpay);
        var date = fnmoment(new Date()).add(-110, "years");
        var maxdate = fnmoment(new Date()).add(-16, "years");
        setcustomerCaption(response.custcaption);
        setMinimumDob(fnmoment(date).format("YYYY-MM-DD"));
        setMaximumDob(fnmoment(maxdate).format("YYYY-MM-DD"));
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Site Detatils", error, context);
        }
      });
    return () => {
      document.body.removeChild(script);
      window.RealexHpp = null;
      if (eventListenerCopy != null) {
        window.removeEventListener("message", eventListenerCopy, false);
      }
    };
  }, []);
  const formik = useFormik({
    initialValues: {
      referencenum: "",
      phone: "",
      dob: "",
      email: "",
      amount: 0,
      SSN: "",
    },
    onSubmit: (values) => {
      if (isMobile) {
        if (termsClicked) {
          setdisableButton(true);

          setImageBase64(values);
        } else {
          setShowQuickPay(false);
        }
      } else {
        setdisableButton(true);

        setImageBase64(values);
      }
    },
  });

  const displayMessage = (evt) => {
    //alert("I got " + evt.data + " from " + evt.origin);
  };
  const RedirectResponse = (RedirecURL) => {
    history.push(RedirecURL);
  };
  const RelalexInit = (values) => {
    window.RealexHpp.setHppUrl(paymentgatewayurl);
    eventListenerCopy = window.RealexHpp.embedded.init(
      "payButtonId",
      "paymentIframeID",
      URLS.GetRelexPostBackAPIURL,
      values,
      RedirectResponse
    );

    //window.RealexHpp.lightbox.init("payButtonId", "http://api.xrs.ie/XRSPaymentPortal/api/HppResponse/RealexResult", values);
  };

  const submitForm = (values, screen) => {
    const xhr = new XMLHttpRequest();
    var data;
    var Reference = values.referencenum;
    var MobileNumber = values.phone;
    var DateOfBirth = values.dob;
    var Email = values.email;
    const ssn = values.SSN;

    var Amount = values.amount + convenienceFee;
    var url =
      selectedregistrationType == null
        ? URLS.GetQuickPayAPIUrl
        : selectedregistrationType == "personal"
        ? URLS.QuickPayPersonal
        : URLS.QuickPayBusiness;
    if (useSSN == "Y") {
      url = URLS.GetQuickPaySSNAPIUrl;
      debugger;
      data = JSON.stringify({
        Reference: Reference,
        SSN: ssn,
        DateOfBirth: DateOfBirth,
        Amount: Amount,
        ScreenShot: screen,
      });
    } else {
      if (selectedregistrationType == null) {
        data = JSON.stringify({
          Reference: Reference,
          MobileNumber: MobileNumber,
          DateOfBirth: DateOfBirth,
          Amount: Amount,
          ScreenShot: screen,
        });
      } else if (selectedregistrationType == "personal") {
        data = JSON.stringify({
          Reference: Reference,
          MobileNumber: MobileNumber,
          Amount: Amount,
          ScreenShot: screen,
        });
      } else {
        data = JSON.stringify({
          Reference: Reference,
          emailAddress: Email,
          Amount: Amount,
          ScreenShot: screen,
        });
      }
    }
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    dispatch(apiActions.loading(true));

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        dispatch(apiActions.loading(false));

        if (xhr.status == 200 || xhr.status == 201) {
          var json = JSON.parse(xhr.responseText);

          if (paymentProvider.toUpperCase() == "TABAPAY") {
            setquickPayAPIData(json);
          } else {
            RelalexInit(json);
          }
          if (isMobile) {
            setShowQuickPay(false);
          }
          setIfamevisible(true);
          window.scrollTo(0, 0);
        } else {
          setdisableButton(false);

          ApiErrorHandler("Quick Pay", xhr, context);
        }
      }
    };
    try {
      xhr.send(data);
    } catch (err) {
      dispatch(apiActions.loading(false));

      ApiErrorHandler("Quick Pay", err, context);
    }
  };
  var tabaPayMessage = function(event) {
    //Check if it is from TabaPay
    let iframe = document.getElementById("SSO");

    if (typeof event.data === "string") {
      if (event.data != "Close") {
        if (event.data.slice(0, 7) == "Error: ") {
          // Error
        } else {
          var asData = event.data.split("|");
          if (asData.length == 3) {
            let quickPayData = quickPayAPIData;
            quickPayData.LASTDIGITS = asData[0];
            quickPayData.EXPIRATIONDATE = asData[1];
            quickPayData.CARDTOKEN = asData[2];
            fetch(URLS.QuickPayResultTabaPay, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(quickPayData),
            })
              .then((res) => {
                if (res.status == 200 || res.status == 201) {
                  return res.json();
                } else {
                  setdisableButton(false);

                  res.json().then((data) => {
                    ApiErrorHandler("Quick Pay", data, context);
                  });
                  if (res.status == 410) {
                    iframe.src = iframe.src;
                    formik.handleSubmit();
                  }
                  throw Error("");
                }
              })
              .then((response) => {
                history.push(response.REDIRECTURL);
              })
              .catch((error) => {
                if (error.message == "") {
                } else {
                  ApiErrorHandler("Quick Pay", error, context);
                }
              });
          } else {
            // Data Error
          }
        }
      } else {
        setIfamevisible(false);
        setdisableButton(false);
        window.removeEventListener("message", tabaPayMessage);
        iframe.src = iframe.src;
      }
    }
  };
  const setImageBase64 = (values) => {
    html2canvas(document.querySelector("#screen"), {
      width: 800,
      backgroundColor: "#ffffff",
      height: 600,
    }).then((canvas) => {
      const newScreenshot = canvas.toDataURL("image/png");
      //document.getElementById("screen").appendChild(canvas);
      setScreenstate({ newScreenshot });
      submitForm(values, newScreenshot);
    });
  };
  const handleClickOpen = () => {
    setAlertOpen(true);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };
  const classes = useStyles();
  console.log(selectedregistrationType);
  return (
    <div>
      <HeaderSection title={sitename}></HeaderSection>
      <Parallax filter="primary">
        <div className={classNames(classes.container, classes.title)}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={isMobile ? 12 : 6}>
                  <Collapse in={showQuickPay}>
                    <Card>
                      <CardBody>
                        <div id="screen">
                          <h2 className={classes.centerOnlyText}>Pay Now</h2>
                          {quickPayText != "" ? (
                            ReactHtmlParser(quickPayText)
                          ) : (
                            <>
                              {" "}
                              <p>Fill in the form with your account details.</p>
                              <p>
                                You will then be redirected to a secure payment
                                page to complete the payment.
                              </p>
                            </>
                          )}

                          {registrationType == "both" ? (
                            <FormControl
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <RadioGroup
                                row
                                aria-labelledby="demo-form-control-label-placement"
                                name="position"
                                defaultValue="top"
                                value={selectedregistrationType}
                                onChange={changeRegisterType}
                              >
                                {" "}
                                <FormControlLabel
                                  value="personal"
                                  control={<Radio />}
                                  label="Personal"
                                  labelPlacement="top"
                                />
                                <FormControlLabel
                                  value="business"
                                  control={<Radio />}
                                  label="Business"
                                  labelPlacement="top"
                                />{" "}
                              </RadioGroup>
                            </FormControl>
                          ) : null}
                          <form
                            className={classes.form}
                            onSubmit={formik.handleSubmit}
                          >
                            {useSSN != "Y" ? (
                              <TextField
                                className={classes.control}
                                required
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  tabindex: 1,
                                  required: true,
                                  placeholder: customerCaption,
                                  onChange: formik.handleChange,
                                  name: "referencenum",
                                }}
                              />
                            ) : null}

                            {useSSN == "Y" ? (
                              <>
                                {" "}
                                <TextField
                                  className={classes.control}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "tel",
                                    name: "SSN",
                                    tabindex: 2,
                                    required: true,
                                    placeholder: textVariables[8],
                                    onChange: formik.handleChange,
                                  }}
                                ></TextField>
                                <TextField
                                  views={["year", "month", "date"]}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  variant="outlined"
                                  inputProps={{
                                    type: "date",
                                    required: true,
                                    tabindex: 3,
                                    placeholder: "Date of Birth",
                                    onChange: formik.handleChange,
                                    name: "dob",
                                    min: minimumDob,
                                    max: maximumDob,
                                  }}
                                  className={classes.control}
                                />
                              </>
                            ) : selectedregistrationType == "business" ? (
                              <TextField
                                className={classes.control}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "email",
                                  name: "email",
                                  tabindex: 2,
                                  required: true,
                                  placeholder: "Email",
                                  onChange: formik.handleChange,
                                }}
                              ></TextField>
                            ) : selectedregistrationType == null ? (
                              <>
                                {" "}
                                <TextField
                                  className={classes.control}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "tel",
                                    name: "phone",
                                    tabindex: 2,
                                    required: true,
                                    placeholder: "Mobile Phone",
                                    onChange: formik.handleChange,
                                  }}
                                ></TextField>
                                <TextField
                                  views={["year", "month", "date"]}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  variant="outlined"
                                  inputProps={{
                                    type: "date",
                                    required: true,
                                    tabindex: 3,
                                    placeholder: "Date of Birth",
                                    onChange: formik.handleChange,
                                    name: "dob",
                                    min: minimumDob,
                                    max: maximumDob,
                                  }}
                                  className={classes.control}
                                />
                              </>
                            ) : (
                              <TextField
                                className={classes.control}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "tel",
                                  name: "phone",
                                  tabindex: 2,
                                  required: true,
                                  placeholder: "Mobile Phone",
                                  onChange: formik.handleChange,
                                }}
                              ></TextField>
                            )}

                            <TextField
                              className={classes.control}
                              showCurrency
                              inputProps={{
                                type: "number",
                                step: 0.01,
                                tabindex: 4,
                                name: "amount",
                                required: true,
                                min: minimumPayAmount,
                                placeholder: "Amount",
                                onChange: formik.handleChange,
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                            {convenienceFee == 0 ? null : (
                              <div style={{ width: "100%" }}>
                                <span
                                  style={{
                                    width: "50%",
                                    textAlign: "left",
                                    fontWeight: "600",
                                    display: "inline-block",
                                  }}
                                >
                                  Debit amount is{" "}
                                </span>
                                <span
                                  style={{
                                    width: "50%",
                                    textAlign: "right",
                                    fontWeight: "600",
                                    display: "inline-block",
                                  }}
                                >
                                  {currency + " "}
                                  {formik.values.amount
                                    ? formik.values.amount + convenienceFee
                                    : 0}
                                </span>
                              </div>
                            )}
                            <BrowserView>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    inputProps={{
                                      required: true,
                                      name: "terms",
                                    }}
                                    color="primary"
                                  />
                                }
                                label="I agree with the Terms and Conditions"
                              />
                            </BrowserView>
                            <MobileView>
                              <Link onClick={handleTermsMobile}>
                                {" "}
                                {termsText} Terms and Conditions
                              </Link>
                            </MobileView>
                            <div className={classes.textCenter}>
                              <Button
                                disabled={disableButton}
                                type="submit"
                                color="primary"
                                fullWidth
                              >
                                PAY NOW
                              </Button>
                              <Button
                                id="payButtonId"
                                className={classes.hide}
                                color="primary"
                                fullWidth
                              >
                                REALEX NOW
                              </Button>
                            </div>
                          </form>
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                  <BrowserView>
                    <Collapse in={!ifamevisible}>
                      <br></br>
                      {ReactHtmlParser(termsApiText)}
                    </Collapse>
                  </BrowserView>
                  <MobileView>
                    <Collapse in={!ifamevisible && !showQuickPay}>
                      <br></br>
                      {ReactHtmlParser(termsApiText)}
                      <Card>
                        <CardBody>
                          <FormControlLabel
                            label="I agree with the Terms and Conditions"
                            control={
                              <Checkbox
                                onChange={HandleTermsClicked}
                                inputProps={{
                                  required: true,
                                  name: "terms",
                                }}
                                color="primary"
                              />
                            }
                          />
                        </CardBody>
                      </Card>
                    </Collapse>
                  </MobileView>
                  <Collapse in={ifamevisible}>
                    {paymentProvider.toUpperCase() == "TABAPAY" ? (
                      <Iframe
                        url={paymentgatewayurl}
                        id="SSO"
                        width="100%"
                        height="350px"
                        position="relative"
                        className={classes.iFrameTop}
                        styles={{ margin: "25px" }}
                      />
                    ) : (
                      <Iframe
                        id="paymentIframeID"
                        width="100%"
                        height="578.8px"
                        position="relative"
                        className={classes.iFrameTop}
                        styles={{ margin: "25px" }}
                      />
                    )}
                  </Collapse>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
        <br></br> <br></br> <br></br>
      </Parallax>
      <LinkSection></LinkSection>
      <FooterSection
        AboutUs={aboutus}
        Address={address}
        WorkingHours={officehours}
        PhoneNumber={phonenumber}
        Email={email}
        companyname={companyname}
      ></FooterSection>
    </div>
  );
}
