import { Collapse, Divider, Hidden } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { primaryColor } from "assets/jss/xrs.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import TextField from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import fnmoment from "moment/min/moment-with-locales";
import React, { useContext } from "react";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import Context from "../../../utils/context";
import * as URLS from "../../../utils/XRSAPIs";
import Paynow from "../../SectionsPage/Sections/PayNowSection.js";
import history from "./../../../utils/history";
import BlueHeader from "./BlueHeaderSection.js";
import Slider from "react-slick";
import * as ACTIONS from "../../../store/actions/actions";
import { ApiErrorHandler } from "utils/apiErrorHandler";

import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
  paragraph: {
    marginTop: "27px !important",
  },
  button: {
    marginRight: theme.spacing(1),
    padding: 13,
  },
  hide: {
    display: "none",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  white: {
    color: "white",
    fontWeight: 500,
    paddingLeft: 30,
  },
  whiteInfo: {
    color: "white",
    fontWeight: 400,
    paddingLeft: 0,
    paddingTop: 10,
  },
  whiteInfoMiddle: {
    color: "white",
    fontWeight: 400,
    paddingLeft: 0,

    textAlign: "center",
  },
  whiteRight: {
    color: "white",
    fontWeight: 500,
    textAlign: "right",
  },
  blueTitle: {
    color: "#0065a4",
    fontWeight: 400,
  },
  blueText: {
    color: "#0065a4",
    fontWeight: 300,
  },
  redText: {
    color: "red",
    fontWeight: 300,
  },
  blackText: {
    color: "black",
    fontWeight: 500,
  },
  Title: {
    fontWeight: 500,
    paddingTop: 30,
  },
  Wellcome: {
    fontWeight: 500,
  },
  BoxFull: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
  },
  Middle: {
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    textAlign: "center",
    border: 0,
  },
  blueTextMiddle: {
    color: "#0065a4",
    fontWeight: 400,
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    textAlign: "center",
    border: 0,
  },

  rightValues: {
    textAlign: "right",
    fontWeight: 300,
  },
  rightRed: {
    textAlign: "right",
    color: "red",
    fontWeight: 300,
  },
  rightBlack: {
    textAlign: "right",
    color: "black",

    fontWeight: 500,
  },
}));

function getSteps() {
  return ["Instructions", "Payments", "Summary"];
}

const SliderSettings = {
  infinite: false,
  touchMove: false,
  arrows: false,
  centerMode: false,
  variableWidth: false,
  dots: false,
  initialSlide: 0,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function WizzardSection() {
  const context = useContext(Context);
  const classes = useStyles();
  const dispatch = useDispatch();

  const createPlanText = useSelector(
    (state) => state.HtmlReducer.createPlanText
  );
  const createPlantoc = useSelector((state) => state.HtmlReducer.createPlantoc);

  const [site, setSite] = React.useState();
  const [topNav, setTopNav] = React.useState(null);
  const [duration, setDuration] = React.useState("");
  const [downpayment, setDownpayment] = React.useState();
  const [paymentDate, setPaymentDate] = React.useState(0);
  const [paymentAmount, setPaymentAmount] = React.useState("0");

  const [freqValues, setfreqValues] = React.useState({
    weekly: 0,
    biweekly: 0,
    montly: 0,
  });

  const [endDate, setEndDate] = React.useState("");
  const [frequency, setFrequency] = React.useState("week");
  const stateAccounts = useSelector((state) => state.AccountsReducer.accounts);
  const stateCustomer = useSelector((state) => state.CustomerReducer.customer);
  const stateOffer = useSelector((state) => state.OfferReducer.offer);
  const stateSite = useSelector((state) => state.SiteReducer.site);
  const planInterestapi = useSelector(
    (state) => state.AccountsReducer.planInterest
  );
  const currency = useSelector((state) => state.GetSiteDetailsReducer.currency);

  const [planInterest, setplanInterest] = React.useState(0);
  const [balanceToPay, setBalanceToPay] = React.useState(0);

  const [latestStartDate, setLatestStartDate] = React.useState("");

  const [paymentVisible, setPaymentVisible] = React.useState(false);

  const [textMessage, setTextMessage] = React.useState("");
  const [payNowSectionMessage, setPayNowSectionMessage] = React.useState("");
  const convenienceFee = useSelector(
    (state) => state.GetSiteDetailsReducer.convenienceFee
  );
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const [step, setStep] = React.useState(0);
  const stateSelectedAccount = useSelector(
    (state) => state.SelectedAccountsReducer.account
  );
  const [paymentType, setpaymentType] = React.useState(null);

  const handleChange = (newValue) => {
    setStep(newValue);
  };

  React.useEffect(() => {
    fnmoment.locale(window.navigator.language.toLowerCase());
    setplanInterest(planInterestapi);
    setpaymentType(stateSelectedAccount.plantype);
    setSite(stateSite);
  }, []);
  const token = sessionStorage.getItem("access_token");
  React.useEffect(() => {
    var account;
    var newDate = new Date();
    account = JSON.stringify({
      ITEMCODE: stateSelectedAccount.itemcode,
      RECNO: stateSelectedAccount.recno,
      STARTDATE: newDate,
      ENDDATE: newDate,
    });

    fetch(URLS.GetReturnAPIUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: account,
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Return API", data, context);
          });
          throw Error("");
        }
      })
      .then((data) => {
        dispatch(ACTIONS.set_Offer(data));

        // setDownpayment(stateSelectedAccount.plannowmin);

        var ammount =
          (Number.parseFloat(stateSelectedAccount.balance) -
            Number.parseFloat(data.WOOFFER1MINPAY)) /
          (duration === 0 ? 1 : duration);
        setPaymentAmount(Math.abs(ammount.toFixed(2).toString()));
        setFrequency("Week");
      }, [])
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Return API", error, context);
        }
      });
  }, []);

  React.useEffect(() => {
    setPaymentDate(fnmoment(fnmoment()).format("YYYY-MM-DD"));
    var dur = duration == "" ? 1 : duration;

    var end = fnmoment(fnmoment()).format("YYYY-MM-DD");
    if (frequency == "Week") {
      end = fnmoment(end)
        .add(7 * dur * (52 / 12) - 7, "Days")
        .format("YYYY-MM-DD");
    }
    //Bi weekly
    else if (frequency == "FORT") {
      end = fnmoment(end)
        .add(14 * dur * (26 / 12) - 14, "Days")
        .format("YYYY-MM-DD");
    } else {
      end = fnmoment(end)
        .add(dur - 1, "M")
        .format("YYYY-MM-DD");
    }
    setEndDate(end);
  }, []);

  const isStepOptional = (step) => {
    return step === 9;
  };

  const submitForm = (screen) => {
    const xhr = new XMLHttpRequest();
    var url = URLS.GetWebOffersAPIUrl;
    var end = paymentDate;
    if (frequency == "Week") {
      end = fnmoment(end).add(7 * (duration - 1), "Days");
    } else {
      end = fnmoment(end).add((duration - 1) * 1, "M");
    }

    var cardref = stateCustomer.cardref;

    var data;
    if (cardref) {
      url = URLS.GetWebOffersCardAPIUrl;
      data = JSON.stringify({
        OFFERID: stateOffer.WOOFFERID,
        DOWNPAYMENT: downpayment,
        REMAINGBAL: 1,
        STARTDATE: paymentDate,
        FREQUENCY: frequency,
        AMOUNT: (
          (stateAccounts[0].balance - downpayment) /
          (duration == 0 ? 1 : duration)
        )
          .toFixed(2)
          .toString(),
        FINALDATE: end,
        FINALAMOUNT: (
          (stateAccounts[0].balance - downpayment) /
          (duration == 0 ? 1 : duration)
        )
          .toFixed(2)
          .toString(),
        APPLYDISCOUNT: "N",
        DISCOUNTOPTIONNUMBER: "0",
        SCREENSHOT: screen,
        PAYERREF: stateCustomer.cardpayerref,
        CARDREF: cardref,
      });
    } else {
      data = JSON.stringify({
        OFFERID: stateOffer.WOOFFERID,
        DOWNPAYMENT: downpayment,
        REMAINGBAL: 1,
        STARTDATE: paymentDate,
        FREQUENCY: frequency,
        AMOUNT: (
          (stateAccounts[0].balance - downpayment) /
          (duration == 0 ? 1 : duration)
        )
          .toFixed(2)
          .toString(),
        FINALDATE: end,
        FINALAMOUNT: (
          (stateAccounts[0].balance - downpayment) /
          (duration == 0 ? 1 : duration)
        )
          .toFixed(2)
          .toString(),
        APPLYDISCOUNT: "N",
        DISCOUNTOPTIONNUMBER: "0",
        SCREENSHOT: screen,
      });
    }
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + token);

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        switch (xhr.status) {
          case 200:
            if (stateCustomer.cardref) {
              var data = JSON.parse(xhr.responseText);
              history.push(
                "/paynow-paymentstatus?AUTHCODE=" +
                  data.AUTHCODE +
                  "&MESSAGE=" +
                  data.MESSAGE +
                  "&RESULTCODE=" +
                  data.RESULTCODE +
                  "&AMOUNT=" +
                  data.AMOUNT +
                  "&PASSREF=" +
                  data.PASSREF
              );
            } else {
              var json = JSON.parse(xhr.responseText);
              RelalexInit(json);
            }

            //setIfamevisible(true);
            break;
          case 401:
            context.authObj.logout();
          case 404:
            alert("Opps Unexpected... " + xhr.responseText);
            break;
          case 400:
            var data = JSON.parse(xhr.responseText);
            history.push(
              "/paynow-paymentstatus?AUTHCODE=" +
                data.AUTHCODE +
                "&MESSAGE=" +
                data.MESSAGE +
                "&RESULTCODE=" +
                data.RESULTCODE +
                "&AMOUNT=" +
                data.AMOUNT +
                "&PASSREF=" +
                data.PASSREF
            );
            break;
          case 500:
            var data = JSON.parse(xhr.responseText);
            history.push(
              "/paynow-paymentstatus?AUTHCODE=" +
                data.AUTHCODE +
                "&MESSAGE=" +
                data.MESSAGE +
                "&RESULTCODE=" +
                data.RESULTCODE +
                "&AMOUNT=" +
                data.AMOUNT +
                "&PASSREF=" +
                data.PASSREF
            );
            break;
          default:
            alert("Opps Unexpected... " + xhr.responseText);
        }
      }
    };
    try {
      xhr.send(data);
    } catch (err) {
      alert(err.description);
    }
  };

  const RelalexInit = (values) => {
    window.RealexHpp.setHppUrl(URLS.GetRealexPayURLAPI);
    window.RealexHpp.embedded.init(
      "payButtonId",
      "paymentIframeID",
      URLS.WebOfferResponseNoCard,
      values
    );
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const PMT = (ir, np, pv, fv, type) => {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0) return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + ir;
    pmt = pmt * -1;
    return pmt.toFixed(2);
  };
  const handleCalculation = (
    endDatePar,
    durationPar,
    downpaymentPar,
    frequencyPar
  ) => {
    let rate;
    var end = endDatePar;
    let principal = stateSelectedAccount.balance - downpaymentPar;
    let dur;
    let rates = {
      weekly: stateSelectedAccount.interestrate / 52 / 100,
      biweekly: stateSelectedAccount.interestrate / 26 / 100,
      montly: stateSelectedAccount.interestrate / 12 / 100,
    };
    let freqValues = {
      weekly: Math.abs(
        (
          Number.parseFloat(
            (
              PMT(rates.weekly, Math.floor(durationPar * 4.34), principal) *
              Math.floor(durationPar * 4.34)
            ).toFixed(2)
          ) / Math.floor(durationPar * 4.34)
        )
          .toFixed(2)
          .toString()
      ),
      biweekly: Math.abs(
        (
          Number.parseFloat(
            (
              PMT(rates.biweekly, Math.floor(durationPar * 2.17), principal) *
              Math.floor(durationPar * 2.17)
            ).toFixed(2)
          ) / Math.floor(durationPar * 2.17)
        )
          .toFixed(2)
          .toString()
      ),
      montly: Math.abs(
        (
          Number.parseFloat(
            PMT(rates.montly, durationPar, principal) * durationPar
          ) / durationPar
        ).toFixed(2)
      ),
    };
    if (frequencyPar == "Week") {
      dur = Math.floor(durationPar * 4.34);
      rate = rates.weekly;
      end = fnmoment(end)
        .add(7 * dur - 7, "Days")
        .format("YYYY-MM-DD");
    } else if (frequencyPar == "FORT") {
      dur = Math.floor(durationPar * 2.17);
      rate = rates.biweekly;
      end = fnmoment(end)
        .add(14 * dur * (26 / 12) - 14, "Days")
        .format("YYYY-MM-DD");
    } else {
      dur = durationPar;
      rate = rates.montly;
      end = fnmoment(end)
        .add(dur - 1, "M")
        .format("YYYY-MM-DD");
    }
    let ammount;
    let setbalanceToPay;
    if (stateSelectedAccount.interestrate > 0) {
      let payment = PMT(rate, dur, principal);
      setbalanceToPay = (payment * dur).toFixed(2);
      setBalanceToPay(setbalanceToPay);
      setplanInterest(setbalanceToPay - principal);
      ammount =
        Number.parseFloat(setbalanceToPay) / (durationPar == 0 ? 1 : dur);
    } else {
      setbalanceToPay = principal;
      setBalanceToPay(principal);
      ammount = Number.parseFloat(principal) / (durationPar == 0 ? 1 : dur);
    }

    ammount = paymentType !== "STANDARD" ? durationPar : ammount;
    setPaymentAmount(
      Math.abs(
        ammount !== ""
          ? Number(ammount)
              .toFixed(2)
              .toString()
          : ammount
      )
    );

    setfreqValues(freqValues);
    setEndDate(end);
  };

  const handleDurationChange = (e) => {
    setDuration(e.target.value);
    handleCalculation(paymentDate, e.target.value, downpayment, frequency);
  };

  const handleFreqChange = (e) => {
    setFrequency(e.target.value);

    handleCalculation(paymentDate, duration, downpayment, e.target.value);
  };
  const handleDownPaymentChange = (e) => {
    if (
      e.target.value > stateSelectedAccount.balance &&
      paymentType == "STANDARD" &&
      stateSelectedAccount.clientname != "RATES" &&
      stateSelectedAccount.clientName != "RENTS"
    ) {
      e.target.value = stateSelectedAccount.balance;
    }

    var value = e.target.value;
    setDownpayment(value);
    var dur = duration == "" ? 1 : duration;
    handleCalculation(paymentDate, dur, value, frequency);
  };

  const handlePayDateChange = (e) => {
    setPaymentDate(e.target.value);

    handleCalculation(e.target.value, duration, downpayment, frequency);
  };

  const submitFormEvent = (event) => {
    event.preventDefault();
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(step)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(step);
    }
    if (step == 1) {
      handleText();
      if (document.forms[0].checkValidity()) {
        setStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        topNav.slickNext();
      }
    } else {
      setStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      topNav.slickNext();
    }
  };

  const values = {
    downpayment:
      convenienceFee != 0 ? Number(downpayment) + convenienceFee : downpayment,
    frequency: frequency,
    duration: duration,
    startdate: paymentDate,
    balanceToPay: balanceToPay,
    amount:
      convenienceFee != 0 ? paymentAmount + convenienceFee : paymentAmount,
    endDate: endDate,
  };

  const formatPaymentAmount = (amount) => {
    return Number.parseFloat(amount).toFixed("2");
  };

  const formatPaymentFrequency = (frequency) => {
    switch (frequency) {
      case "Month":
        return "monthly";
      case "FORT":
        return "bi weekly";
      default:
        return "weekly";
    }
  };

  const handleText = () => {
    const paymentAmountFormatted = formatPaymentAmount(
      paymentType !== "STANDARD"
        ? duration
        : convenienceFee != 0
        ? paymentAmount + convenienceFee
        : paymentAmount
    );

    const paymentFrequencyFormatted = `recurring ${formatPaymentFrequency(
      frequency
    )} payments of`;

    const payNowSectionMessage = `I agree to pay ${
      site.companyname
    } ${currency}${formatPaymentAmount(
      convenienceFee != 0
        ? Number.parseFloat(Number(downpayment) + convenienceFee)
        : Number.parseFloat(downpayment)
    )} from my card now, followed by ${paymentFrequencyFormatted} starting on ${fnmoment(
      paymentDate
    ).format("L")}`;

    const textMessage = (
      <div>
        <div style={{ display: "inline-block" }}>
          {`I agree to pay ${site.companyname} ${currency}${formatPaymentAmount(
            convenienceFee != 0
              ? Number.parseFloat(Number(downpayment) + convenienceFee)
              : Number.parseFloat(downpayment)
          )} from my card now, followed by ${paymentFrequencyFormatted}`}
        </div>

        <NumberFormat
          style={{ display: "inline-block", marginLeft: 5, marginRight: 5 }}
          value={paymentAmountFormatted}
          displayType={"text"}
          fixedDecimalScale={true}
          thousandSeparator={true}
          decimalScale={2}
          prefix={currency}
        />

        <div style={{ display: "inline-block" }}>
          {` starting on ${fnmoment(paymentDate).format("L")}`}
        </div>
      </div>
    );

    setPayNowSectionMessage(payNowSectionMessage);
    setTextMessage(textMessage);
  };

  const handleFinish = () => {
    setPaymentVisible(true);
  };

  const handleBack = () => {
    let newSkipped = skipped;
    if (isStepSkipped(step)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(step);
    }
    setStep((prevActiveStep) => prevActiveStep - 1);
    setSkipped(newSkipped);
    topNav.slickPrev();
  };

  const handleSkip = () => {
    if (!isStepOptional(step)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(step);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setStep(0);
  };

  return (
    <div>
      <BlueHeader></BlueHeader>
      <Collapse in={!paymentVisible}>
        <Card style={{ backgroundColor: primaryColor[19], margin: 0 }}>
          <CardBody>
            <form onSubmit={submitFormEvent}>
              <Slider
                ref={(slider) => {
                  setTopNav(slider);
                }}
                {...SliderSettings}
              >
                <div key={0} className="col ">
                  <div className={step == 0 ? "step-cell active" : "step-cell"}>
                    <a onClick={() => handleChange(0)}>Instructions</a>
                    <div className="arrow-outer">
                      <div className="arrow-inner"></div>
                    </div>
                  </div>
                </div>

                <div key={1} className="col">
                  <div className={step == 1 ? "step-cell active" : "step-cell"}>
                    <div className="arrow-outer arrow-outer-before">
                      <div className="arrow-inner"></div>
                    </div>

                    <Link onClick={() => handleChange(1)}>Payments</Link>

                    <div className="arrow-outer">
                      <div className="arrow-inner"></div>
                    </div>
                  </div>
                </div>

                <div key={2} className="col">
                  <div className={step == 2 ? "step-cell active" : "step-cell"}>
                    <div className="arrow-outer arrow-outer-before">
                      <div className="arrow-inner"></div>
                    </div>
                    Summary
                    <div className="arrow-outer">
                      <div className="arrow-inner"></div>
                    </div>
                  </div>
                </div>
              </Slider>

              <div
                style={{ display: step == 0 ? "block" : "none" }}
                className="budget-cell d-flex flex-column"
              >
                <div className="center-container-940">{createPlanText}</div>
              </div>
              <div
                style={{ display: step == `1` ? "block" : "none" }}
                className="budget-cell d-flex flex-column"
              >
                <div className="center-container-940">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4>Initial Payment</h4>
                        </GridItem>
                        <Hidden smDown>
                          <GridItem xs={12} sm={12} md={5}>
                            <p className={classes.paragraph}>
                              I would like my initial payment to be
                            </p>
                          </GridItem>
                        </Hidden>
                        <GridItem xs={12} sm={12} md={4}>
                          <TextField
                            className={classes.control}
                            showCurrency
                            label="I would like my initial payment to be"
                            helperText={`Minimum Amount ${
                              stateSelectedAccount.plannowmin == undefined
                                ? ""
                                : stateSelectedAccount.plannowmin
                            }`}
                            inputProps={{
                              type: "number",
                              step: 0.01,
                              min: stateSelectedAccount.plannowmin,
                              name: "downpayment",
                              required: "required",
                              value: downpayment,

                              onChange: (e) => {
                                handleDownPaymentChange(e);
                              },
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <Hidden smDown>
                          <GridItem xs={12} sm={12} md={3}>
                            <p className={classes.paragraph}>
                              Minimum Amount{" "}
                              <NumberFormat
                                value={stateSelectedAccount.plannowmin}
                                displayType={"text"}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={currency}
                              />
                            </p>
                          </GridItem>
                        </Hidden>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4>
                            {paymentType == "STANDARD"
                              ? "Duration"
                              : "Recurring amount"}
                          </h4>
                        </GridItem>
                        <Hidden smDown>
                          <GridItem xs={12} sm={12} md={5}>
                            <p className={classes.paragraph}>
                              {paymentType == "STANDARD"
                                ? "I would like to pay balance over a period of " +
                                  duration +
                                  (Number(duration) === 1
                                    ? " month"
                                    : " months")
                                : "I would like my recurring amount to be"}
                            </p>
                          </GridItem>
                        </Hidden>
                        <GridItem xs={12} sm={12} md={4}>
                          <TextField
                            labelProps={{ style: { width: "500px" } }}
                            className={classes.control}
                            label={
                              paymentType == "STANDARD"
                                ? "I would like to pay balance over a period of " +
                                  duration +
                                  (Number(duration) === 1
                                    ? " month"
                                    : " months")
                                : "I would like my recurring amount to be"
                            }
                            helperText={`Maximum Term
                            ${
                              stateSelectedAccount.planmaxduration == undefined
                                ? ""
                                : stateSelectedAccount.planmaxduration
                            }
                            months`}
                            inputProps={{
                              type: "number",
                              step: paymentType == "MINPAYMENT" ? 0.01 : 1,
                              name: "duration",
                              required: "required",
                              value: duration,
                              max:
                                paymentType == "MINPAYMENT"
                                  ? null
                                  : stateSelectedAccount.planmaxduration,
                              min:
                                paymentType == "STANDARD"
                                  ? 1
                                  : frequency == "Week"
                                  ? stateSelectedAccount.planminpayment
                                  : frequency == "Month"
                                  ? (
                                      (stateSelectedAccount.planminpayment *
                                        52) /
                                      12
                                    ).toFixed(2)
                                  : (
                                      stateSelectedAccount.planminpayment * 2
                                    ).toFixed(2),
                              onChange: (e) => {
                                handleDurationChange(e);
                              },
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <Hidden smDown>
                          <GridItem xs={12} sm={12} md={3}>
                            <p className={classes.paragraph}>
                              {paymentType == "STANDARD"
                                ? `Maximum Term ${stateSelectedAccount.planmaxduration} months`
                                : `Minimum Payment Amount ${
                                    currency + paymentType == "STANDARD"
                                      ? 1
                                      : frequency == "Week"
                                      ? stateSelectedAccount.planminpayment
                                      : frequency == "Month"
                                      ? (
                                          (stateSelectedAccount.planminpayment *
                                            52) /
                                          12
                                        ).toFixed(2)
                                      : (
                                          stateSelectedAccount.planminpayment *
                                          2
                                        ).toFixed(2)
                                  }`}
                            </p>
                          </GridItem>
                        </Hidden>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4>Frequency</h4>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                          <p style={{ textAlign: "left", marginTop: 10 }}>
                            I would like my recurring payments to occur every
                          </p>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <RadioGroup
                            row={true}
                            aria-label="freq"
                            name="freq"
                            required
                            onChange={(e) => {
                              handleFreqChange(e);
                            }}
                          >
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <FormControlLabel
                                  value="Week"
                                  control={
                                    <Radio
                                      color="primary"
                                      checked={frequency === "Week"}
                                    />
                                  }
                                  label={`Weekly Payments ${
                                    isNaN(freqValues.weekly) ||
                                    freqValues.weekly === 0
                                      ? ""
                                      : "of " +
                                        currency +
                                        freqValues.weekly.toFixed(2)
                                  }`}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <FormControlLabel
                                  value="FORT"
                                  control={
                                    <Radio
                                      color="primary"
                                      checked={frequency === "FORT"}
                                    />
                                  }
                                  label={`Bi Weekly Payments ${
                                    isNaN(freqValues.biweekly) ||
                                    freqValues.biweekly === 0
                                      ? ""
                                      : "of " +
                                        currency +
                                        freqValues.biweekly.toFixed(2)
                                  }`}
                                />
                              </GridItem>

                              <GridItem xs={12} sm={12} md={12}>
                                <FormControlLabel
                                  value="Month"
                                  control={
                                    <Radio
                                      color="primary"
                                      checked={frequency === "Month"}
                                    />
                                  }
                                  label={`Monthly Payments ${
                                    isNaN(freqValues.montly) ||
                                    freqValues.montly === 0
                                      ? ""
                                      : "of " +
                                        currency +
                                        freqValues.montly.toFixed(2)
                                  }`}
                                />{" "}
                              </GridItem>
                            </GridContainer>
                          </RadioGroup>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <Hidden mdUp>
                          <GridItem xs={12} sm={12} md={12}>
                            <h4>Payment Date</h4>
                          </GridItem>
                        </Hidden>

                        <Hidden smDown>
                          <GridItem xs={12} sm={12} md={12}>
                            <h4 style={{ marginTop: 30 }}>Payment Date</h4>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={5}>
                            <p className={classes.paragraph}>
                              I would like my recurring payments to start on
                            </p>
                          </GridItem>
                        </Hidden>
                        <GridItem xs={12} sm={12} md={4}>
                          <TextField
                            label="I would like my recurring payments to start on"
                            views={["year", "month", "date"]}
                            helperText={`Latest Start Date: ${fnmoment()
                              .add(stateSelectedAccount.planmaxstart, "days")
                              .format("L")}`}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            variant="outlined"
                            inputProps={{
                              type: "date",
                              required: "required",
                              onChange: (e) => {
                                handlePayDateChange(e);
                              },
                              format: "DD-MM-YYYY",
                              value: paymentDate,
                              name: "paydate",
                              min: fnmoment().format("YYYY-MM-DD"),
                              max: fnmoment()
                                .add(stateSelectedAccount.planmaxstart, "days")
                                .format("YYYY-MM-DD"),
                            }}
                            className={classes.control}
                          ></TextField>
                        </GridItem>
                        <Hidden smDown>
                          <GridItem xs={12} sm={12} md={3}>
                            <p className={classes.paragraph}>
                              Latest Start Date:{" "}
                              {fnmoment()
                                .add(stateSelectedAccount.planmaxstart, "days")
                                .format("L")}
                            </p>
                          </GridItem>
                        </Hidden>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
              <div
                style={{ display: step == 2 ? "block" : "none" }}
                className="budget-cell d-flex flex-column"
              >
                <div className="center-container-940">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <h3
                        style={{ fontSize: "24px" }}
                        className={classes.Title}
                      >
                        Summary
                      </h3>
                      <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                          <h4 className={classes.blueText}>
                            {paymentType == "STANDARD"
                              ? "Total Balance"
                              : "Current Balance"}
                          </h4>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <h4 className={classes.rightValues}>
                            {" "}
                            <NumberFormat
                              value={stateSelectedAccount.balance}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </h4>
                        </GridItem>
                        {convenienceFee == 0 ? (
                          <>
                            {" "}
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.blueText}>
                                Initial Payment
                              </h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.rightValues}>
                                {" "}
                                <NumberFormat
                                  value={downpayment}
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={currency}
                                />
                              </h4>
                            </GridItem>
                          </>
                        ) : null}

                        <>
                          {" "}
                          <GridItem xs={6} sm={6} md={6}>
                            <h4 className={classes.blueText}>
                              {stateSelectedAccount.interestrate > 0
                                ? "Balance after Initial Payment"
                                : "Balance to Pay"}
                            </h4>
                          </GridItem>
                          <GridItem xs={6} sm={6} md={6}>
                            <h4 className={classes.rightValues}>
                              {" "}
                              <NumberFormat
                                value={
                                  stateSelectedAccount.balance - downpayment
                                }
                                displayType={"text"}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={currency}
                              />
                            </h4>
                          </GridItem>
                        </>

                        {stateSelectedAccount.interestrate > 0 ? (
                          <GridItem xs={6} sm={6} md={6}>
                            <h4 className={classes.blueText}>
                              Forecast Interest
                            </h4>
                          </GridItem>
                        ) : null}
                        {stateSelectedAccount.interestrate > 0 ? (
                          <GridItem xs={6} sm={6} md={6}>
                            <h4 className={classes.rightValues}>
                              {" "}
                              <NumberFormat
                                value={planInterest}
                                displayType={"text"}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={currency}
                              />
                            </h4>
                          </GridItem>
                        ) : null}
                        {paymentType == "STANDARD" &&
                        stateSelectedAccount.interestrate != 0 ? (
                          <>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.blueText}>
                                Schedule Balance
                              </h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.rightValues}>
                                {" "}
                                <NumberFormat
                                  value={balanceToPay}
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={currency}
                                />
                              </h4>
                            </GridItem>
                          </>
                        ) : null}
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <h3
                        style={{ fontSize: "24px" }}
                        className={classes.Title}
                      >
                        Schedule Details
                      </h3>
                      <GridContainer>
                        {paymentType == "STANDARD" ? (
                          <>
                            {" "}
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.blueText}>Term</h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.rightValues}>
                                {`${duration} months`}
                              </h4>
                            </GridItem>
                          </>
                        ) : null}

                        <GridItem xs={6} sm={6} md={6}>
                          <h4 className={classes.blueText}>Start Date</h4>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <h4 className={classes.rightValues}>
                            {fnmoment(paymentDate).format("L")}
                          </h4>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <h4 className={classes.blueText}>
                            Payment Frequency
                          </h4>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <h4 className={classes.rightValues}>
                            {frequency == "Week"
                              ? "Weekly"
                              : frequency == "FORT"
                              ? "Bi Weekly"
                              : "Monthly"}
                          </h4>
                        </GridItem>
                        {convenienceFee == 0 ? (
                          <>
                            {" "}
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.blueText}>
                                Payment Amount
                              </h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.rightValues}>
                                <NumberFormat
                                  value={
                                    paymentType !== "STANDARD"
                                      ? duration
                                      : paymentAmount
                                  }
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={currency}
                                />
                              </h4>
                            </GridItem>
                          </>
                        ) : null}

                        {paymentType == "STANDARD" ? (
                          <>
                            {" "}
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.blueText}>End Date</h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.rightValues}>
                                {fnmoment(endDate).format("L")}
                              </h4>
                            </GridItem>
                          </>
                        ) : null}
                      </GridContainer>
                    </GridItem>
                    {convenienceFee != 0 ? (
                      <>
                        {" "}
                        <GridItem xs={12} sm={12} md={6}>
                          <hr />

                          <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.blueText}>
                                Initial Payment
                              </h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.rightValues}>
                                {" "}
                                <NumberFormat
                                  value={downpayment}
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={currency}
                                />
                              </h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.blackText}>Fee</h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.rightBlack}>
                                {" "}
                                <NumberFormat
                                  value={convenienceFee}
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={currency}
                                />
                              </h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.redText}>Initial Debit</h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.rightRed}>
                                {" "}
                                <NumberFormat
                                  value={Number(downpayment) + convenienceFee}
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={currency}
                                />
                              </h4>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <hr />
                          <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.blueText}>
                                Payment Amount
                              </h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.rightValues}>
                                <NumberFormat
                                  value={
                                    paymentType !== "STANDARD"
                                      ? duration
                                      : paymentAmount
                                  }
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={currency}
                                />
                              </h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.blackText}>Fee</h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.rightBlack}>
                                {" "}
                                <NumberFormat
                                  value={convenienceFee}
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={currency}
                                />
                              </h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.redText}>
                                Recurring Debit
                              </h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <h4 className={classes.rightRed}>
                                {" "}
                                <NumberFormat
                                  value={
                                    paymentType !== "STANDARD"
                                      ? duration
                                      : paymentAmount + convenienceFee
                                  }
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={currency}
                                />
                              </h4>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </>
                    ) : null}
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4 className={classes.blueTextMiddle}>{textMessage}</h4>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.blueTextMiddle}>
                        {createPlantoc}
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
              <div className={classes.Middle}>
                {step === steps.length ? (
                  <div>
                    <Button onClick={handleReset} className={classes.button}>
                      Reset
                    </Button>
                  </div>
                ) : (
                  <div>
                    <div>
                      <Button
                        disabled={step === 0}
                        onClick={handleBack}
                        color="primary"
                        className={classes.button}
                      >
                        Back
                      </Button>
                      {isStepOptional(step) && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSkip}
                          className={classes.button}
                        >
                          Skip
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={
                          step === steps.length - 1 ? handleFinish : handleNext
                        }
                        className={classes.button}
                      >
                        {step === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </CardBody>
        </Card>
      </Collapse>
      <Collapse in={paymentVisible}>
        {paymentVisible ? (
          <Paynow
            value={values}
            source="CreatePaymentPlan"
            showdate={false}
            enabled="false"
            message={payNowSectionMessage}
          ></Paynow>
        ) : null}
      </Collapse>
    </div>
  );
}
