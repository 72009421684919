import {
  API_ACTION_TYPES,
  GET_SITE_DETAILS_ACTION_TYPES,
  HTML_ACTION_TYPES,
  CAMERA_ACTION_TYPES,
  DOCUMENTS_ACTION_TYPES,
  RESET_STORE,
} from "./redux_action_types";
import ReactHtmlParser from "react-html-parser";

export const apiActions = {
  loading,
  error,
  clear,
  code,
};

function loading(payload) {
  return { type: API_ACTION_TYPES.LOADING, payload };
}

function code(payload) {
  return { type: API_ACTION_TYPES.CODE, payload };
}

function error(payload) {
  return { type: API_ACTION_TYPES.ERROR, payload };
}

function clear() {
  return { type: API_ACTION_TYPES.CLEAR };
}

export const getSiteDetailsActions = {
  logo,
  footerImage,
  currency,
  paymentProvider,
  devNum,
  convenienceFee,
  uploadImagesToBudget,
};

function currency(payload) {
  return { type: GET_SITE_DETAILS_ACTION_TYPES.CURRENCY, payload };
}
function logo(payload) {
  return { type: GET_SITE_DETAILS_ACTION_TYPES.LOGO, payload };
}
function footerImage(payload) {
  return { type: GET_SITE_DETAILS_ACTION_TYPES.FOOTER_IMAGE, payload };
}
function devNum(payload) {
  return { type: GET_SITE_DETAILS_ACTION_TYPES.DEPT_NUM, payload };
}
function convenienceFee(payload) {
  return { type: GET_SITE_DETAILS_ACTION_TYPES.CONVENIENCE_FEE, payload };
}
function uploadImagesToBudget(payload) {
  return {
    type: GET_SITE_DETAILS_ACTION_TYPES.UPLOAD_IMAGE_TO_BUDGET,
    payload,
  };
}

function paymentProvider(payload) {
  return { type: GET_SITE_DETAILS_ACTION_TYPES.PAYMENT_PROVIDER, payload };
}

export const htmlActions = {
  termsQuickPay,
  termsPlan,
  termsDiscount,
  viewOfferText,
  createPlanText,
  createPlantoc,
  createBudgetText,
  viewAccountText,
  setBurgerAnimation,
};
function termsDiscount(payload) {
  payload = ReactHtmlParser(payload);
  return { type: HTML_ACTION_TYPES.TERMS_DISCOUNT, payload };
}
function termsQuickPay(payload) {
  payload = ReactHtmlParser(payload);
  return { type: HTML_ACTION_TYPES.TERMS_QUICK_PAY, payload };
}
function termsPlan(payload) {
  payload = ReactHtmlParser(payload);
  return { type: HTML_ACTION_TYPES.TERMS_PLAN, payload };
}
function viewOfferText(payload) {
  payload = ReactHtmlParser(payload);
  return { type: HTML_ACTION_TYPES.VIEW_OFFER_TEXT, payload };
}
function createPlanText(payload) {
  payload = ReactHtmlParser(payload);
  return { type: HTML_ACTION_TYPES.CREATE_PLAN_TEXT, payload };
}
function createPlantoc(payload) {
  payload = ReactHtmlParser(payload);
  return { type: HTML_ACTION_TYPES.CREATE_PLANTOC, payload };
}
function createBudgetText(payload) {
  payload = ReactHtmlParser(payload);
  return { type: HTML_ACTION_TYPES.CREATE_BUDGET_TEXT, payload };
}
function viewAccountText(payload) {
  payload = ReactHtmlParser(payload);
  return { type: HTML_ACTION_TYPES.VIEW_ACCOUNT_TEXT, payload };
}

function setBurgerAnimation(payload) {
  return { type: HTML_ACTION_TYPES.BURGER_BUTTON_ANIMATION, payload };
}
//Camera Actions
export const cameraActions = {
  openCamera,
  setCapturedImage,
  addCapturedImage,
  deleteDocument,
  resetDocuments,
};
function openCamera(payload) {
  return { type: CAMERA_ACTION_TYPES.OPEN_CAMERA, payload };
}
function setCapturedImage(payload) {
  return { type: CAMERA_ACTION_TYPES.SET_IMAGE, payload };
}
function addCapturedImage(payload) {
  return { type: CAMERA_ACTION_TYPES.ADD_CONFIRMED_IMAGE, payload };
}
function deleteDocument(payload) {
  return { type: CAMERA_ACTION_TYPES.REMOVE_CONFIRMED_IMAGE, payload };
}
function resetDocuments() {
  return { type: CAMERA_ACTION_TYPES.RESET_CONFIRMET_IMAGES };
}

export const documentsActions = {
  openDocumet,
  viewDocumet,
};

function viewDocumet(payload) {
  return { type: DOCUMENTS_ACTION_TYPES.DOCUMENT_TO_BE_VIEWED, payload };
}
function openDocumet(payload) {
  return { type: DOCUMENTS_ACTION_TYPES.OPEN, payload };
}

// to reset the state of redux store
export const resetStore = () => {
  return {
    type: RESET_STORE,
  };
};
