import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import quickPaymentStyle from "../../assets/jss/xrs/views/quickPaymentStyle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { useFormik } from "formik";
import { getSiteDetailsActions } from "store/redux/redux-actions/redux_actions";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import ReactHtmlParser from "react-html-parser";

import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FooterSection from "../SectionsPage/Sections/FooterSection.js";
import HeaderSection from "../SectionsPage/Sections/HeaderSection.js";
import LinkSection from "../SectionsPage/Sections/LinksSection.js";
import TextField from "components/CustomInput/CustomInput.js";
import Iframe from "react-iframe";
import fnmoment from "moment";
import queryString from "query-string";
import Paper from "@material-ui/core/Paper";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import React, { useContext } from "react";
import Context from "../../utils/context";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from "@material-ui/core/Fade";

import * as URLS from "../../utils/XRSAPIs";
import { set } from "date-fns";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(landingPageStyle);
const useStyles1 = makeStyles(quickPaymentStyle);
export default function QuickPayPaymentStatus(props) {
  const currency = useSelector((state) => state.GetSiteDetailsReducer.currency);

  const [page, setPage] = React.useState(1);
  const [ifamevisible, setIfamevisible] = React.useState(false);
  const [sitename, setSitename] = React.useState("");
  const [companyname, setCompanyname] = React.useState("");
  const [aboutus, setAboutus] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [officehours, setOfficehours] = React.useState("");
  const [phonenumber, setPhonenumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [authcode, setAuthcode] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [resultcode, setResultCode] = React.useState("");
  const [ammount, setAmmount] = React.useState("");
  const [passref, setPassref] = React.useState("");
  const dispatch = useDispatch();
  const context = useContext(Context);
  const [paymentstatustext, setpaymentstatustext] = React.useState("");

  const [vissibleSucc, setVissibleSucc] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  React.useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    setAmmount(parsed.AMOUNT);
    setMessage(parsed.MESSAGE);
    setAuthcode(parsed.AUTHCODE);
    setPassref(parsed.PASSREF);
    setResultCode(parsed.RESULTCODE);

    if (parsed.RESULTCODE == "00") {
      setVissibleSucc(true);
    } else {
      setVissibleSucc(false);
    }

    fetch(URLS.GetSiteDetatilsAPIUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Welcome Details", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        dispatch(getSiteDetailsActions.logo(response.logoimageurl));
        dispatch(getSiteDetailsActions.footerImage(response.footerimageurl));
        dispatch(getSiteDetailsActions.currency(response.sitecurrency));
        dispatch(
          getSiteDetailsActions.uploadImagesToBudget(
            response.uploadimagestobudget
          )
        );
        if (response.usesconveniencefee) {
          dispatch(
            getSiteDetailsActions.convenienceFee(response.conveniencefee)
          );
        }
        setSitename(response.sitename);
        setCompanyname(response.companyname);
        setAboutus(response.aboutus);
        setOfficehours(response.officehours);
        setPhonenumber(response.phonenumber);
        setAddress(response.address);
        setEmail(response.email);
        setpaymentstatustext(response.paymentstatustext);

        var date = fnmoment(new Date()).add(-110, "years");
        var maxdate = fnmoment(new Date()).add(-16, "years");
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Site Detatils", error, context);
        }
      });
  }, [page]);

  const classes = useStyles();
  const classes1 = useStyles1();

  return (
    <div>
      <HeaderSection title={sitename}></HeaderSection>

      <Parallax filter="primary">
        <div className={classNames(classes.container, classes.title)}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.middle}
            >
              <GridContainer>
                <Hidden mdUp>
                  <GridItem
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                  >
                    <Paper
                      variant="outlined"
                      elevation={10}
                      className={
                        vissibleSucc ? classes1.shown : classes1.hidden
                      }
                    >
                      <Paper variant="outlined" className={classes1.green}>
                        {message}
                      </Paper>
                      <Paper elevation={0} className={classes1.blueBox}>
                        <p className={classes1.DiscountBlue}>
                          Thank you for your payment of:
                        </p>
                        <p className={classes1.AmmountItem}>
                          <NumberFormat
                            value={ammount}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </p>

                        <p className={classes1.DiscountBlue}>
                          Your Auth Code is:
                        </p>
                        <p className={classes1.DiscountItem}>{authcode}</p>

                        <p className={classes1.footer}>
                          Your account has been updated to reflect this
                          transaction.
                        </p>
                      </Paper>
                    </Paper>
                    <Paper
                      variant="outlined"
                      elevation={10}
                      className={
                        !vissibleSucc ? classes1.shown : classes1.hidden
                      }
                    >
                      <Paper variant="outlined" className={classes1.green}>
                        {message}
                      </Paper>
                      <Paper elevation={0} className={classes1.blueBox}>
                        <p className={classes1.DiscountBlue}>
                          Your payment of:
                        </p>
                        <p className={classes1.AmmountItemRed}>
                          {currency + " " + ammount}
                        </p>

                        <p className={classes1.footerRed}>
                          Your payment has not been taken!.
                        </p>
                      </Paper>
                    </Paper>
                  </GridItem>
                </Hidden>
                <Hidden smDown>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <Paper
                      variant="outlined"
                      elevation={10}
                      className={
                        vissibleSucc ? classes1.shown : classes1.hidden
                      }
                    >
                      <Paper variant="outlined" className={classes1.green}>
                        {message}
                      </Paper>
                      <Paper elevation={0} className={classes1.blueBox}>
                        <p className={classes1.DiscountBlue}>
                          Thank you for your payment of:
                        </p>
                        <p className={classes1.AmmountItem}>
                          <NumberFormat
                            value={ammount}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </p>

                        <p className={classes1.DiscountBlue}>
                          Your Auth Code is:
                        </p>
                        <p className={classes1.DiscountItem}>{authcode}</p>

                        <p className={classes1.footer}>
                          Your account has been updated to reflect this
                          transaction.
                        </p>
                      </Paper>
                    </Paper>
                    <Paper
                      variant="outlined"
                      elevation={10}
                      className={
                        !vissibleSucc ? classes1.shown : classes1.hidden
                      }
                    >
                      <Paper variant="outlined" className={classes1.green}>
                        {message}
                      </Paper>
                      <Paper elevation={0} className={classes1.blueBox}>
                        <p className={classes1.DiscountBlue}>
                          Your payment of:
                        </p>
                        <p className={classes1.AmmountItemRed}>
                          {currency + " " + ammount}
                        </p>

                        <p className={classes1.footerRed}>
                          Your payment has not been taken!.
                        </p>
                      </Paper>
                    </Paper>
                  </GridItem>
                </Hidden>

                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <Paper variant="outlined" elevation={10}>
                    <Paper variant="outlined" className={classes1.green}>
                      Did you know?
                    </Paper>
                    <Paper elevation={0} className={classes1.blueBox}>
                      {ReactHtmlParser(paymentstatustext)}
                    </Paper>
                  </Paper>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <LinkSection></LinkSection>
      <FooterSection
        AboutUs={aboutus}
        Address={address}
        WorkingHours={officehours}
        PhoneNumber={phonenumber}
        Email={email}
        companyname={companyname}
      ></FooterSection>
    </div>
  );
}
