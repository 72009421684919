import Icon from "@material-ui/core/Icon";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import React, { useContext } from "react";
import Context from "../../utils/context";
import history from "../../utils/history";
import * as ACTIONS from "../../store/actions/actions";

import { useSelector, useDispatch } from "react-redux";

const drawerWidth = 150;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    border: "none",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(3),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    border: "none",
  },
  drawerPaper: {
    width: drawerWidth,
    top: 60,
    border: 0,
    paddingTop: 30,
  },
  linkButton: {
    padding: "25px 25px",
    width: 35,
    textAlign: "center",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  textCenter: {
    textAlign: "center",
    fontSize: 13,
    fontWeight: 400,
    marginTop: 1,
    marginBottom: 0,
  },
  textCenterAccNum: {
    textAlign: "center",
    fontSize: 11,
    fontWeight: 400,
    marginTop: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function AccountItem(props) {
  const classes = useStyles();
  const theme = useTheme();
  const page = props.page;
  const account = props.account;
  const context = useContext(Context);
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(ACTIONS.set_selectedAccount(props.account));
    props.onClick();

    history.push(`/viewAccount?acc=` + props.account.accountnumber);
  };
  return (
    <div className={classes.textCenter}>
      <Button
        onClick={onClickHandler}
        className={classes.linkButton}
        color={page == props.account.balancedesc ? "primary" : "dark"}
      >
        <Icon className="fa fa-handshake-o" color="white" />
      </Button>

      <h4 className={classes.textCenter}>{props.account.balancedesc}</h4>

      <h4 className={classes.textCenterAccNum}>
        {props.account.accountnumber}
      </h4>
    </div>
  );
}
